import { FaEdit, FaTrash, FaPlus, FaSearch, FaShare } from "react-icons/fa"; // Importando ícones do Font Awesome
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { API } from "./services/api";
import { useListaProdutosMercadoLivre } from "./hooks/useListaProdutosMercadoLivre";

export default function ListaProdutosMercadoLivre() {
  const {
    isAllSelected,
    produtos,
    selectedIds,
    currentDateTime,
    isPromotion,
    rotina,
    setRotina,
    handleCheckboxChange,
    handleSelectAllChange,
    getProdutos,
    ModalAddProduct,
    handleEdit,
    getLogin,
    editLogin,
  } = useListaProdutosMercadoLivre();

  useEffect(() => {
    getLogin();
    getProdutos();
  }, []);

  return (
    <div className="overflow-x-auto">
      <div className="mb-4">
        <label>
          <input
            type="radio"
            value="promotion"
            checked={isPromotion === "S"}
            onChange={() => editLogin("S")}
          />
          Usar Promoção
        </label>
        <label className="ml-4">
          <input
            type="radio"
            value="fullPrice"
            checked={isPromotion === "N"}
            onChange={() => editLogin("N")}
          />
          Alterar Valor Cheio
        </label>
      </div>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200 text-gray-700">
            {/* <th className="py-2 px-4 border-b">
              <input
                type="checkbox"
                className="w-4 h-4 rounded border-gray-300"
                checked={isAllSelected}
                onChange={handleSelectAllChange}
              />
            </th> */}
            <th className="py-2 px-4 border-b">MLB ID</th>
            <th className="py-2 px-4 border-b">Nome do Produto</th>
            <th className="py-2 px-4 border-b">Valor do Produto</th>
            <th className="py-2 px-4 border-b">Valor Mínimo</th>
            <th className="py-2 px-4 border-b">Ações</th>
          </tr>
        </thead>
        <tbody>
          {produtos?.map((produto: any) => (
            <tr key={produto.produto_mlbid} className="hover:bg-gray-100">
              {/* <td className="py-2 px-4 border-b">
                <input
                  type="checkbox"
                  className="w-4 h-4 rounded border-gray-300"
                  checked={selectedIds.includes(produto.produto_mlbid)}
                  onChange={() => handleCheckboxChange(produto.produto_mlbid)}
                />
              </td> */}
              <td className="py-2 px-4 border-b">{produto.produto_mlbid}</td>
              <td className="py-2 px-4 border-b">{produto.produto_titulo}</td>
              <td className="py-2 px-4 border-b">
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(produto.produto_preco)}
              </td>
              <td className="py-2 px-4 border-b">
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(produto.produto_valorminimo)}
              </td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => handleEdit(produto)} // Função para editar
                  className="bg-yellow-500 text-white p-2 rounded mr-1"
                >
                  <FaEdit />
                </button>
                {/* <button
                  className="bg-red-500 text-white p-2 rounded"
                >
                  <FaTrash />
                </button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-end mt-4">
        <button
          onClick={ModalAddProduct}
          className="bg-green-500 text-white p-2 rounded mr-1"
          title="Adicionar produto"
        >
          <FaPlus />
        </button>
        {/* <button
          onClick={checkAllTime}
          className="bg-blue-500 text-white p-2 rounded"
          title="Atualizar preços"
        >
          <FaSearch />
        </button> */}
        <button
          onClick={() => {
            navigator.clipboard
              .writeText(localStorage.getItem("USER_ID") ?? "")
              .then(() => {
                Swal.fire(
                  "Sucesso!",
                  "Informações da conta copiadas para a área de transferência.",
                  "success"
                );
              })
              .catch((err) => {
                Swal.fire(
                  "Erro",
                  "Falha ao copiar as informações da conta.",
                  "error"
                );
              });
          }}
          className="mx-1 bg-purple-500 text-white p-2 rounded"
          title="Copiar número da conta"
        >
          <FaShare />
        </button>
      </div>
      <div className="mt-4">
        <p>Data e Hora Última Atualização: {currentDateTime}</p>
      </div>
    </div>
  );
}
